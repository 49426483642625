import './Botao.css'

const Botao = () => {
    return (
        <div className="btn">
                <a href="https://wa.me/5511991007079">entrar em contato</a>
            </div>
    )
}

export default Botao